<template>
  <div class="blurb-card column"  v-if="Blurb">
    <div class="blurb-title row">
      <span>{{$t('lesson')}}</span>
      <span>{{Blurb.number}}</span>
      <span>{{Blurb.name}}</span>
      <AudioToggle v-if="Blurb.audioOverview" :key="Blurb.audioOverview.id"
      :white-background="true" :audio="Blurb.audioOverview"/>
    </div>
    <div class="blurb-content">
      <p  v-html="Blurb.description"></p>  
    </div>
    <div class="blurb-footer">
      <essential-question v-if="Blurb.essentialQuestionOverview" :key="Blurb.essentialQuestionOverview.id"
        :audio="Blurb.essentialQuestionOverview.audio" :message="Blurb.essentialQuestionOverview.question"/>
    </div>
  </div>
</template>

<script>
import EssentialQuestion from '@/common/EssentialQuestion.vue'
import AudioToggle from '@/common/AudioToggle.vue'
export default {
  name: 'BlurCard',
  components: {
    EssentialQuestion,
    AudioToggle
  },
  computed: {
    Blurb(){
      return this.$store.state.currentBlurb
    }
  }
}
</script>

<style lang="less">

img{
  align-self: center;
  cursor: pointer;
}


.blurb-card{
  
  width: 624px;

  .blurb-title {	
    position: relative;
    grid-area: title;
    min-height: 76px;	
    width: 624px;	
    background-color: #0A1554;
      
    .audio-toggle{
      align-self: center;
      margin-left: auto;
      margin-right: 24px;
      height: 24px;
      width: 24px;
    }
    span:not(.audio-toggle){
      height: 24px;	
      color: rgba(255,255,255,0.6);
      font-weight: bold;	
      letter-spacing: 0.25px;	
    }

    span:first-child{
      width: 85px;	
      font-size: 19.64px;	
      line-height: 24px;
      margin-left: 24px;
      margin-top: 31px;
      text-transform: capitalize;
      text-overflow: unset;
      white-space: normal;
    }

    span:nth-child(2){
      height: 72px;	
      width: 61px;	
      font-size: 58.49px;	
      font-weight: 800;	
      letter-spacing: -0.5px;	
      line-height: 72px;
      margin-top: -7px;
    }

    span:nth-child(3){
      width: 384px;	
      color: #FFFFFF;	
      font-size: 19.64px;
      line-height: 24px;
      padding: 24px;
      height: auto;
      margin-top: 0;
      white-space: normal;
    }
    
    &::before{
      content: '';
      position: absolute;
      height: 20px;
      width: 154px;
      background-color: #0A1554;
      top: -19px;
      left: 10px;
      -webkit-transform: skew(45deg);
      transform: skew(45deg);
    }

    &::after{
      content: '';
      position: absolute;
      height: 20px;
      width: 124px;
      background-color: #0A1554;
      top: -19px;
      left: 0px;
    }
    
  }

  .blurb-content{
    grid-area: content;
    min-height: 164px;	
    width: 624px;	
    background-color: #FFFFFF;	
    box-shadow: 0 4px 20px 0 rgba(10,21,84,0.2);
    padding: 24px 16px;
    box-sizing: border-box;
    > p{
      margin-block-start: 0em;
      min-height: 129.25px;
      max-width: 576px;	
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;	
      letter-spacing: 0.5px;	
      line-height: 28px;
      overflow-y: auto;
    }

  }

  .blurb-footer{
    grid-area: footer;
    >div{
      max-width: 624px;
    }
  }
  .essential-question span{
    
    max-width: 500px;
    padding: 8px;
    height: auto !important;
    min-height: 16px;
    color: #000  !important;
    font-size: 15px  !important;
    overflow: auto;
    white-space: normal;
  }
  
}
</style>

